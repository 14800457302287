import { render, staticRenderFns } from "./spanBadge.vue?vue&type=template&id=7480ea4c"
import script from "./spanBadge.vue?vue&type=script&lang=js"
export * from "./spanBadge.vue?vue&type=script&lang=js"
import style0 from "./spanBadge.vue?vue&type=style&index=0&id=7480ea4c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentTooltip: require('/usr/src/app/components/basic/contentTooltip.vue').default})
